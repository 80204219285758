import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {retrieveMyAccount} from '../redux/user.redux'
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { Link, navigate } from 'gatsby'
import Layout from '../components/Layout'
import { logIn } from '../redux/auth.redux';

const styles = theme => ({
  paper: {
		margin: 'auto',
		width: '350px',
		padding: 30,
	},
  container: {
    display: 'block',
    textAlign: 'center',
    margin: 'auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '60%',
    maxWidth: '400px',
  },
  button: {
  	marginTop: 30,
  	marginBottom: 30,
  },
  header: {
  	marginTop: 30,
  },
});

class Login extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired,
	};
	constructor(props) {
		super(props);
		this.auth = {email: null, password: null};
		this.state = {err: null, showPassword: false};
	}

	componentDidMount() {
		if (this.props.location.state && this.props.location.state.destination) {
			this.setState(() => ({ destination: this.props.location.state.destination }));
		}
	}

	componentDidUpdate() {

		if (this.props.auth && this.props.auth.isLoggedIn) {
			navigate(this.state.destination ? this.state.destination : '/account');
		} 
	}


	render() {
		const { classes } = this.props;
		function validateEmail (email) {
			const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regexp.test(email);
		}
		function validatePassword (password) {
			const regexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/
			return regexp.test(password);
		}
		const setEmail = (input) => {
			this.auth.email = input;
		}
		const setPassword = (input) => {
			this.auth.password = input;
		}

		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				login()
			}
		}

		const login = () => {
			if (!validateEmail(this.auth.email)) {
				this.setState({err: 'Please enter a valid email address'});
				return;
			} else if (!validatePassword(this.auth.password)) {
				this.setState({err: 'Wrong Password'});
				return;
			}  else {
				this.props.logIn(this.auth);
				this.setState({err: null});
			}
		}

		const handlePasswordMask = () => {
			this.setState((state) => ({showPassword: !state.showPassword}));
		}

		return (
            <Layout hideSocial={true}>
				<span className={classes.paper}>
					<form  className={classes.container}>
						<Typography className={classes.header} variant='h5' color='primary'>Welcome Back!</Typography>
						<div>
							<TextField
								id="standard-email"
								label="Email"
								className={classes.textField}
								onChange={(event) => setEmail(event.target.value)}
								margin="normal"
								onKeyPress={(event) => handleKeyPress(event)}
							/>
						</div>
						<div>
							<TextField
								id="standard-password-input"
								label="Password"
								className={classes.textField}
								type={this.state.showPassword ? 'text' : 'password'}
								autoComplete="current-password"
								margin="normal"
								onChange={(event) => setPassword(event.target.value)}
								onKeyPress={(event) => handleKeyPress(event)}
								InputProps={{
									endAdornment: (
									  <InputAdornment position="end">
										<RemoveRedEye onClick={handlePasswordMask}/>
									  </InputAdornment>
									),
								}}
							/>
						</div>
						<div>
							<Button className={classes.button} variant="contained" size="medium" color="primary" onClick={()=>login()}> Login </Button>
						</div>
						<div>
							<Typography color='primary' gutterBottom={true}><Link to="/signup" state={{destination: this.state.destination ? this.state.destination : null}}>Create new account</Link></Typography>
						</div>
						<div>
							<Typography color='primary' gutterBottom={true}><Link to="/forgotPassword">Forgot password? (Retrieve your account)</Link></Typography>
						</div>
						<div>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.state.err}</Typography>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.props.auth.err}</Typography>
						</div>
					</form>
				</span>
			</Layout>
		);
	}
	
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStatetoProps = (state) => {return { auth : state.auth }};
const actionCreators = { logIn, retrieveMyAccount };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(Login));